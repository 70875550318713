import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <p>process.env.REACT_APP_ENV: {process.env.REACT_APP_ENV}</p>
        <p>
          process.env.REACT_APP_NEW_VALUE: {process.env.REACT_APP_NEW_VALUE}
        </p>
        <p>process.env.REACT_APP_BERENGER: {process.env.REACT_APP_BERENGER}</p>
        <p>process.env.REACT_APP_BLOP: {process.env.REACT_APP_BLOP}</p>
        <p>Bonjour</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
